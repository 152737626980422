<template>
  <div id="page" class="site doks">
    <a href="#main" aria-label="Idź do treści"></a>
    <app-header></app-header>
    <router-view id="main" />
    <app-footer></app-footer>
  </div>
</template>
<script>
import AppHeader from "./views/AppHeader.vue";
import AppFooter from "./views/AppFooter.vue";
import AppSidebar from "./components/Sidebar.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "page",
  head: {
    title: function() {
      return {
        inner: this.pageTitle,
        separator: " - ",
      };
    },
    meta: function() {
      return [{ name: "og:image", content: this.ogimage, id: "ogimage" }];
    },
  },
  computed: {
    pageTitle() {
      return this.$store.state.page.pageTitle;
    },
    ogimage() {
      return this.$store.state.page.ogimage;
    },
  },
  watch: {
    pageTitle: function(npageTitle, opageTitle) {
      this.$emit("updateHead");
    },
  },
  components: {
    "app-header": AppHeader,
    "app-footer": AppFooter,
    "app-sidebar": AppSidebar,
  },
  mounted() {
    window.addEventListener("click", (event) => {
      const { target } = event;
      // handle only links that do not reference external resources
      if (target && target.matches("a:not([href*='://'])") && target.href) {
        // some sanity checks taken from vue-router:
        // https://github.com/vuejs/vue-router/blob/dev/src/components/link.js#L106
        const {
          altKey,
          ctrlKey,
          metaKey,
          shiftKey,
          button,
          defaultPrevented,
        } = event;
        // don't handle with control keys
        if (metaKey || altKey || ctrlKey || shiftKey) return;
        // don't handle when preventDefault called
        if (defaultPrevented) return;
        // don't handle right clicks
        if (button !== undefined && button !== 0) return;
        // don't handle if `target="_blank"`
        if (target && target.getAttribute) {
          const linkTarget = target.getAttribute("target");
          if (/\b_blank\b/i.test(linkTarget)) return;
        }
        // don't handle same page links/anchors
        const url = new URL(target.href);
        const to = url.pathname;
        if (window.location.pathname !== to && event.preventDefault) {
          event.preventDefault();
          this.$router.push(to);
        }
      }
    });
  },
};
</script>
