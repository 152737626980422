<template>
  <div
    id="sidebar-primary"
    class="col-md-12 col-lg-3 sidebar-primary widget-area"
  >
    <div id="search-3" class="widget widget_search">
      <p class="widget-title">Wyszukiwarka</p>
      <form @submit.prevent>
        <label>
          <span class="screen-reader-text">Szukaj:</span>
          <input
            type="search"
            class="search-form__field"
            placeholder="Szukaj …"
            v-model="searchForThis"
            name="s"
            title="Search for:"
          />
        </label>
        <button
          type="submit"
          class="search-form__submit btn btn-primary"
          @click="searchThis()"
        >
          <span class="icon-search"></span>
          <span class="screen-reader-text">Szukaj</span>
        </button>
      </form>
    </div>
    <div id="text-2" class="widget widget_text">
      <p class="widget-title">DZIELNICOWY OŚRODEK KULTURY URSYNÓW</p>
      <div class="textwidget">
        <p class="fc1">
          ul. Kajakowa 12b <br />02-838 Warszawa <br />tel. 22 125 56 02
        </p>
        <p class="fc3">godziny pracy: <br />Pn-Ndz: 9-21</p>
      </div>
    </div>
  </div>
</template>
<script>
// let Config = require("../app.config.js");
export default {
  name: "app-sidebar",
  components: {},
  data: () => {
    return {
      searchForThis: "",
    };
  },
  methods: {
    searchThis() {
      // console.log(this.searchForThis)
      if (this.searchForThis) {
        this.$store.dispatch(`page/searchFor`, encodeURI(this.searchForThis));
        this.$router.push("/szukaj");
      }
    },
  },
};
</script>
