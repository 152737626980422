<template>
  <header id="masthead" class="site-header centered container" role="banner">
    <div class="top-panel">
      <div class="top-panel__wrap container">
        <div class="top-panel__message">
          <a id="bip" href="https://www.bip.gov.pl/" rel="noopener noreferrer" aria-label="bip"></a>
          <a id="ursynowTop" href="https://ursynow.pl/" rel="noopener noreferrer" aria-label="ursynow"></a>
        </div>
      </div>
    </div>
    <div class="header-container">
      <div class="header-container_wrap container">
        <div class="bip-container">
          <div>
            <a href="https://www.bip.gov.pl/" rel="noopener noreferrer" aria-label="bip" class="bip-header"></a>
            <a href="https://dokursynow.pl/pjm" rel="noopener noreferrer" aria-label="bip" class="pjm-header"></a>
          </div>
        </div>
        <div class="header-container__flex">
          <div class="header-container__center">
            <div class="site-branding">
              <div class="site-logo">
                <router-link to="/" class="site-logo__link" rel="home">
                  <img src="/img/logo_inv.png" alt="Logo DOK Ursynów" class="site-link__img" width="228" height="74" />
                </router-link>
              </div>
            </div>
            <fixed-header :threshold="100">
              <div>
                <nav id="site-navigation" class="main-navigation stuckMenu" role="navigation">
                  <button class="menu-toggle cp" @click="toggleMenu">Menu</button>

                  <ul class="menu" id="primary-menu" v-bind:class="{ active: isActive }">
                    <menuItem v-for="item in menuItems" v-if="item.menu_item_parent == 0" :key="item.object_id" :class="{ active: item.active }" v-bind:url="changeUrl(item.url)" v-bind:object_id="item.object_id" v-bind:post_parent="item.post_parent" v-bind:title="item.title" v-bind:type_label="item.type_label">
                      <ul class="sub-menu">
                        <menuItem v-for="subItem in menuItems" :key="subItem.object_id" v-if="subItem.menu_item_parent == item.ID" v-bind:object_id="subItem.object_id" v-bind:url="changeUrl(subItem.url)" v-bind:ID="subItem.ID" v-bind:post_parent="subItem.post_parent" v-bind:title="subItem.title" v-bind:type_label="subItem.type_label"></menuItem>
                      </ul>
                    </menuItem>
                  </ul>
                </nav>
              </div>
            </fixed-header>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
  import FixedHeader from "vue-fixed-header";
  import { mapGetters, mapActions } from "vuex";
  import menuItem from "../components/MenuItem.vue";
  let Config = require("../app.config.js");
  const fetchInitialData = (store) => {
    return store.dispatch(`menu/getMenu`, "menu_main");
  };
  export default {
    name: "app-header",
    components: {
      FixedHeader,
      menuItem,
    },
    data: () => {
      return {
        isActive: false,
        isMenuActive: false,
        ayContrast: false,
        siteName: "",
      };
    },
    prefetch: fetchInitialData,
    computed: {
      ...mapGetters("menu", ["menuItems", "testIt"]),
    },
    methods: {
      toggleContrast() {
        document.querySelector("body").classList.toggle("ayContrast");
        this.ayContrast = !this.ayContrast;
      },
      normalSize() {
        document.querySelector("body").style.fontSize = "16px";
      },
      addSize() {
        document.querySelector("body").style.fontSize = "18px";
      },
      addAddSize() {
        document.querySelector("body").style.fontSize = "20px";
      },
      changeUrl(url) {
        return url;
        // return url.replace(Config.wpDomain, "/");
      },
      ...mapActions("menu", {
        getMenu: "getMenu",
      }),
      toggleMenu() {
        this.isActive = !this.isActive;
      },
      closeMenu() {
        this.isMenuActive = false;
      },
      relativePath(url) {
        // url = url.replace("https://", "");
        // var domain = url.split("/")[0];
        // return url.replace(domain, "");
        return url;
      },
      loadData() {
        fetchInitialData(this.$store);
      },
    },
    mounted() {
      var self = this;
      document.querySelector("#site-navigation").addEventListener("click", function (event) {
        if (event.target.classList.contains("nav-item")) {
          self.toggleMenu();
        }
      });
      this.loadData();
    },
  };
</script>
<style lang="scss">
  @media only screen and (max-width: 768px) {
    #page div.header-container {
      background-image: none;
    }
    .header-container .main-navigation {
      text-align: center;
      padding: 25px 0 0;
    }
    .main-navigation > .menu > li > ul.sub-menu {
      // left: 50%;
      margin-left: 0;
      li {
        background: none !important;
        border: 0 !important;
      }
      a {
        text-indent: 0;
      }
    }
    #primary-menu.active {
      a {
        padding: 3px;
      }
      display: block;
      li a {
        text-align: center;
      }
      a:after {
        content: none;
      }
      ul {
        display: block;
        padding-top: 0;
      }
    }
    .vue-fixed-header--isFixed {
      background: #fff;
      ul#primary-menu {
        margin-top: 60px;
        background-color: rgba(61, 22, 56, 0.88);
        & > li > a,
        a {
          color: #fff;
        }
      }
    }
  }
  .menu-toggle {
    color: #fff;
  }

  .vue-fixed-header--isFixed {
    border-bottom: 1px solid #ccc;
    .menu-toggle {
      background: #fff;
      float: right;
      color: #000;
    }
  }
  .site-branding {
    position: relative;
  }
  .bip-container {
    display: flex;
    justify-content: flex-end;
    > div {
      display: flex;
      background: #fff;
      padding: 10px;
      padding-right: 0;
    }
  }
  .bip-header {
    width: 100px;
    display: block;
    height: 0;
    padding-top: 53px;
    overflow: hidden;
    background: #fff
      url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJcAAAA1CAMAAAB7lYmuAAAAwFBMVEX///+JIRvaJR0AAADv5eWsdnV9AACCAACGFAq2IxzGJBzBIxy7IxzLJByZIRufIhusIhulIhuRIRuECwD65uXBnJzx8fHQ0ND39/fonJzYAACjo6M4ODiFhYXc3Nzi4uKwsLDExMRBQUGWlpZtbW1JSUkYGBiOjo5jY2MmJiZ2dnZSUlK7u7umamouLi6hAADAAACQAAC8kZHey8rUnJzenJyxDgDPsrLNEACkYF2XSUd0AACeVlSPLijXv7+UPTpg4+YFAAADs0lEQVRYw+3Z2XLbNhiG4Vf9uSRtk2apAbQgCW6iuEm2o9qO7Ti+/7vqAWnHlne3sjUZfQcaUBufASD8wIhfxrx79+tvv799++HDxz/ev/80uTVvfF4sf4/5ure3d3h4uLu7u7u/vx/emmDOxsYVAK68uE6zzXAlEYBqL65jOzZ6+7qunAQwoEAZYgsqAVMWShkwCkXyCq6mjlqUoCroMmKLXrQRtoq6dApdqrtapuu6/ZG32rhwCUacueqaFlmU0loQZ0Rp0YhaD8s7PQaA5enJzfklyTVXk2aZo7XQFtmULILKrckVjC52lve4JCO25D1AayFp6p6sW6fr29Gx/x3wl4DZOZ5/BwAjSVqhBCO2HFypaKeISwWRGLIpyNpcn8IgCHy8YA7eZLgAIClLh4mhr7M0oc+gL2uHakuwNTgLs3XNr+Czr5ZnwdILffgW+t7yPDh6+HPdmldZLzgBloHvhT4mOAOW4cNVSAnrdh0Pj17g4wXfAC88fv1qY84+L2Ee7qjgBC8Ml+AHXwCMNZe9U8Tm/q/pHf3NtV8nAInG9XcV4fTOdTUIzyfhGUyCIy+chOeT8MwAKLmc0mKzB1zTnvpm2WwLgCKmv6ss6MWdX/l9chrMFSxP5yo4OT8Njld/Yua5k6m1ADZ+7ljeXGRHjVgR5RpZxNSNpKgm7pBC6lRymIlYqBvJmGrKFICqWFSKymFj4joXjY3REZSN6L7JxTqxkkNaSTpuWh6xyK64OlNblECao8QpmSkkVtIaSWiNEtIIm19zUc9oBleE7bAxOqdvSBeQiHIyQ5wS58Q8zsWX5YpLEw+usoBKKwEjBkmYauhn1TiHfrjEYbvR1RboZnTFMQCS4QSiJBFb2Me6VmeVuAtXOwMZXVA5ck0z08KsvumqaRz1dVcxBWhLRpeTH5vPZ7kKlEBfGS3qwiWOPEmEQnCSGnN1HGNyS25TiUmntCVFjI6G92XinHECi4QoNook4s9r8e6PAtNB7bA9qgZsNNWoenxe0SbEkS0hi6KCqabIAKhsXkJWtb2FeFErUkvSgs6jIq3LOlUdtA5VL0pcdMt5aH9/Pwxuz1PPQ83lovq0jaLOWT0/fvz/zo991122n7LxMbOqX6fLXSlB2jyluxTrdP2nbF1b19a1dW1dW9fWtXX9rK7LffTXvb3Dy43052t58wquv8YcHBwc/DNkZzXz8OVdj4ofbqaLocc2zzXANtCFH26mCz/cTBfzcDNdzIPNdDHf1P9rj17sTv8C63iBTJ1RQzsAAAAASUVORK5CYII=)
      no-repeat center center;
    background-size: contain;

    padding-left: 10px;
    padding-right: 10px;
  }
</style>
