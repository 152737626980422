const single = state => state.single
const homepage = state => state.homepage
const event = state => state.event
const events = state => state.events
const calendarium = state => state.calendarium
const searchResult = state => state.searchResult
const ideas = state => state.ideas
const activities = state => state.activities
const pageIsLoading = state => state.pageIsLoading
const postIsLoading = state => state.postIsLoading
const eventsIsLoading = state => state.eventsIsLoading
const activitiesIsLoading = state => state.activitiesIsLoading
const pageTitle = state => state.pageTitle
const ideasIsLoading = state => state.ideasIsLoading
const contactFormResult = state => state.contactFormResult
const freshFormResult = state => state.freshFormResult
const volunteering = state => state.volunteering
const volunteeringIsLoading = state => state.volunteeringIsLoading
const ogimage = state => state.ogimage

export {
  events,
  event,
  single,
  homepage,
  calendarium,
  searchResult,
  ideas,
  activities,
  pageIsLoading,
  postIsLoading,
  eventsIsLoading,
  activitiesIsLoading,
  pageTitle,
  ideasIsLoading,
  contactFormResult,
  freshFormResult,
  volunteering,
  volunteeringIsLoading,
  ogimage
}
