<template>
  <section id="vwp-newsletter" class>
    <div class="NewsletterContainer">
      <div class="container">
        <section
          id="footer-full-width-area"
          class="footer-full-width-area widget-area"
        >
          <aside
            id="custom_html-2"
            class="widget_text widget widget_custom_html"
          >
            <div class="textwidget custom-html-widget">
              <div style="width:100%;">
                <div
                  style="background-color: #53224f;border:0px solid #000000;border-radius:5px; padding: 0px  24px;overflow:hidden;"
                  id="fm_form_1"
                >
                  <div class="form_container">
                    <form
                      class="form_subscribe freshmail_form_1"
                      @submit.prevent
                    >
                      <p
                        style="color: #ffffff; font-size:18pt; text-align:center;"
                        class="text_header"
                      >
                        Zapisz się do biuletynu email
                      </p>
                      <div style="margin: 0px 0%;">
                        <label for="form[email]" class="inputLabel">
                          email
                          <input
                            type="email"
                            class="field"
                            placeholder="Wpisz tu email"
                            v-model="contactForm.email"
                            id="form[email]"
                            v-on:keyup.enter="submitFreshmail()"
                            name="form[email]"
                            style="padding:1px 5px;max-width:100%;width:240px; height:40px;font-size:12pt; border:0px solid #b7b7b7;"
                        /></label>
                      </div>
                      <p
                        style="margin-top:8px;color:#ffffff;font-size:9pt;text-align:left;"
                      >
                        <input
                          v-model="contactForm.accept"
                          type="checkbox"
                          name="fm_form_agree"
                          id="fm_form_agree"
                          class="checkbox_agreement"
                        />
                        <label for="fm_form_agree">
                          Akceptuję
                          <a href="/regulamin-newsletter/" name="regulamin"
                            >regulamin</a
                          >
                        </label>
                      </p>

                      <div
                        @click="submitFreshmail"
                        class="form_subscribe_button button"
                        style="box-shadow:none;border-radius:0px;width:120px; height:40px;font-size:11pt; "
                      >
                        Zapisz mnie
                      </div>
                      <div v-if="messageContact" class="messageInfo">
                        {{ messageContact }}
                      </div>
                      <div v-if="sending" class="messageInfo">Wysyłanie...</div>

                      <div v-if="messageContactResult" class="messageInfo">
                        {{ messageContactResult }}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </aside>
        </section>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "app-newsletter",
  data() {
    return {
      contactForm: {
        name: "",
        phone: "",
        email: "",
        accept: false,
      },
      sending: false,
      messageContact: false,
      messageContactResult: false,
    };
  },
  computed: {
    ...mapGetters("page", ["single", "pageIsLoading"]),
    freshFormResult() {
      return this.$store.state.page.freshFormResult;
    },
  },

  watch: {
    freshFormResult: function(newfreshFormResult) {
      this.sending = false;
      // console.log(newfreshFormResult)
      if (newfreshFormResult.includes("Dodano")) {
        this.messageContactResult = "Zapisano";
      } else if (
        newfreshFormResult.includes("Subscribers already exists in this")
      ) {
        this.messageContactResult = "Podany email jest już zapisany";
      } else if (newfreshFormResult.includes("Error")) {
        this.messageContactResult = "Błąd wysyłki";
      } else {
        this.messageContactResult = "Wysłano";
      }
    },
  },
  methods: {
    ...mapActions("page", {
      getPage: "getPage",
    }),
    submitFreshmail() {
      if (this.contactForm.email == "") {
        this.messageContact = "Uzupełnij email";
      } else if (this.contactForm.accept == "") {
        this.messageContact = "Zaakceptuj regulamin";
      } else if (!/(.+)@(.+){2,}\.(.+){2,}/.test(this.contactForm.email)) {
        this.messageContact = "Niepoprawny adres email";
      } else {
        this.messageContact = false;
        this.messageContactResult = false;
        if (!this.sending) {
          this.sending = true;
          this.$store.dispatch(`page/freshForm`, this.contactForm.email);
        }
      }
    },
  },
};
</script>

<style lang="scss"></style>
