<template>
  <li>
    <router-link
      class="is-tab nav-item newmenuitem"
      :to="cutName(url)"
      v-if="type_label === 'Strona'"
      :data-url="url"
      >{{ title }}</router-link
    >
    <a :href="url" class="is-tab nav-item" rel="noopener" v-else>{{ title }}</a>
    <slot></slot>
  </li>
</template>

<script>
export default {
  name: "menuItem",
  props: {
    url: String,
    post_parent: Number,
    title: String,
    object_id: String,
    active: String,
    type_label: String,
  },
  methods: {
    cutName(url) {
      return url.replace("https://dokursynow.pl", "");
    },
  },
};
</script>

<style scoped lang="scss"></style>
