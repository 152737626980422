<template>
  <div>
    <app-newsletter class="hero is-primary"></app-newsletter>
    <footer
      id="colophon"
      class="site-footer centered container"
      role="contentinfo"
    >
      <div class="dokBottomMenu">
        <div class="container">
          <div class="row">
            <div
              class="col-xs-12 col-sm-6 col-md-3"
              v-for="item in menuItemsFooter"
              :key="item.object_id"
              v-if="item.menu_item_parent == 0"
            >
              <p class="dokFooterTitle">{{ item.title }}</p>
              <ul>
                <menuItem
                  v-for="subItem in menuItemsFooter"
                  :key="subItem.object_id"
                  v-if="subItem.menu_item_parent == item.ID"
                  v-bind:object_id="subItem.object_id"
                  v-bind:url="changeUrl(subItem.url, subItem.type_label)"
                  v-bind:ID="subItem.ID"
                  v-bind:post_parent="subItem.post_parent"
                  v-bind:title="subItem.title"
                  v-bind:type_label="subItem.type_label"
                ></menuItem>
              </ul>
            </div>

            <div class="col-xs-12 col-sm-6 col-md-3">
              <p class="dokFooterTitle">Wyszukiwarka</p>
              <form @submit.prevent aria-label="Wyszukiwarka">
                <label class="cw"
                  >Wyszukaj hasło:
                  <input
                    aria-label="Wyszukaj tutaj"
                    type="search"
                    class="search-form__field"
                    placeholder="Szukaj …"
                    v-model="searchForThis"
                    v-on:keyup.enter="searchThis()"
                    name="s"
                    title="Search for:"
                  />
                </label>
                <button
                  type="submit"
                  aria-label="Przycisk szukaj"
                  class="search-form__submit btn btn-primary"
                  @click="searchThis()"
                  name="szukaj"
                >
                  <span class="icon-search"></span>
                </button>
              </form>
              <span class="errorSearch" v-if="searchError">
                {{ searchError }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-container dokfooterss">
        <div class="site-info container">
          <div class="site-info__mid-box">
            <div class="logosFooter">
              <a
                href="http://ursynow.pl/"
                name="ursynow"
                id="ursynowFooter"
                rel="noopener noreferrer"
                aria-label="strona ursynow.pl"
              ></a>
              <a
                href="http://nadajemykulture.pl/"
                name="nadajemy"
                id="nadajemyFooter"
                rel="noopener noreferrer"
                aria-label="strona nadajemykulture.pl"
              ></a>
              <a
                href="https://www.haloursynow.pl/"
                name="haloursynow"
                id="haloFooter"
                rel="noopener noreferrer"
                aria-label="strona haloursynow.pl"
              ></a>
              <a
                href="http://www.bip.dokursynow.pl"
                name="bip"
                id="bipFooter"
                rel="noopener noreferrer"
                aria-label="strona bip.dokursynow.pl"
              ></a>
            </div>
            <div class="footer-copyright" @click="footerClicked()">
              © 2019 DZIELNICOWY OŚRODEK KULTURY “URSYNÓW”
            </div>
            <a
              href="http://szydlo.it/"
              name="szydlo.it"
              id="szydloIT"
              rel="noopener noreferrer"
              >szydlo.it</a
            >
          </div>
        </div>
      </div>
    </footer>
    <cookie-law
      buttonText="akceptuje"
      message="Używamy plików cookies. Kontynuując korzystanie z serwisu bez zmiany ustawień swojej przeglądarki, wyrażasz zgodę na stosowanie plików cookies zgodnie z naszą Polityką Prywatności."
    ></cookie-law>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import AppNewsletter from "./AppNewsletter.vue";
import menuItem from "../components/MenuItem.vue";
import CookieLaw from "vue-cookie-law";
let Config = require("../app.config.js");
const fetchInitialData = (store) => {
  return store.dispatch(`menu/getMenu`, "menu_footer");
};
export default {
  name: "app-footer",
  components: {
    "app-newsletter": AppNewsletter,
    menuItem,
    CookieLaw,
  },
  data: () => {
    return {
      socialLinks: [],
      searchForThis: "",
      clickedCount: 0,
      searchError: "",
    };
  },
  prefetch: fetchInitialData,
  computed: {
    ...mapGetters("menu", ["menuItemsFooter", "testIt"]),
  },
  mounted() {
    this.loadData();
  },
  methods: {
    footerClicked() {
      this.clickedCount++;
      if (this.clickedCount % 3 === 0) {
        this.$store.dispatch(`page/goToEdit`, this.$route.params.page);
      }
    },
    changeUrl(url, pageType) {
      if (pageType === "Strona") {
        //return url.replace(Config.wpDomain, "/");
        return url;
      } else {
        return url;
      }
    },
    ...mapActions("menu", {
      getMenu: "getMenu",
    }),
    toggleMenu() {
      this.isMenuActive = !this.isMenuActive;
    },
    closeMenu() {
      this.isMenuActive = false;
    },
    relativePath(url) {
      // url = url.replace("https://", "");
      // var domain = url.split("/")[0];
      // return url.replace(domain, "");
      return url;
    },
    loadData() {
      fetchInitialData(this.$store);
    },
    searchThis() {
      if (this.searchForThis) {
        this.$store.dispatch(`page/searchFor`, encodeURI(this.searchForThis));
        if (this.$router.currentRoute.name !== "szukaj") {
          this.$router.push("/szukaj");
        }
      } else {
        this.searchError = "Wyszukana fraza powinna mieć chociaż jeden znak";
      }
    },
  },
};
</script>
