import * as actions from './actions'
import * as getters from './getters'
import * as mutations from './mutations'

const defaultState = {
  homepage: '',
  single: '',
  pageIsLoading: false,
  event: '',
  postIsLoading: false,
  events: '',
  eventsIsLoading: '',
  calendarium: '',
  searchResult: [{
    title: '<div class="text-input__loading"><div class="text-input__loading--line"></div></div>',
    link: '#'
  }],
  ideas: '',
  ideasIsLoading: false,
  activities: '',
  activitiesIsLoading: false,
  pageTitle: 'Start',
  contactFormResult: false,
  freshFormResult: false,
  freshFormResult2: false,
  volunteering: '',
  volunteeringIsLoading: false,
  ogimage: ''
}

const inBrowser = typeof window !== 'undefined'
// if in browser, use pre-fetched state injected by SSR
const state = (inBrowser && window.__INITIAL_STATE__) ? window.__INITIAL_STATE__.page : defaultState

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
