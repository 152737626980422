const PAGE_UPDATED = (state, page) => {
  state.single = page
}
const HOMEPAGE_UPDATED = (state, page) => {
  state.homepage = page
}
const EVENT_UPDATED = (state, page) => {
  state.event = page
}
const EVENTS_UPDATED = (state, page) => {
  state.events = page
}
const CALENDARIUM_UPDATED = (state, page) => {
  state.calendarium = page
}
const SEARCH_RESULT = (state, searchResult) => {
  state.searchResult = searchResult
}
const IDEAS_UPDATED = (state, ideas) => {
  state.ideas = ideas
}
const ACTIVITIES_UPDATED = (state, activities) => {
  state.activities = activities
}
const PAGE_IS_LOADING_UPDATED = (state, pageIsLoading) => {
  state.pageIsLoading = pageIsLoading
}
const POST_IS_LOADING_UPDATED = (state, postIsLoading) => {
  state.postIsLoading = postIsLoading
}
const EVENTS_IS_LOADING_UPDATED = (state, eventsIsLoading) => {
  state.eventsIsLoading = eventsIsLoading
}
const ACTIVITIES_IS_LOADING_UPDATED = (state, activitiesIsLoading) => {
  state.activitiesIsLoading = activitiesIsLoading
}
const SET_PAGE_TITLE = (state, pageTitle) => {
  state.pageTitle = pageTitle
}
const IDEAS_IS_LOADING_UPDATED = (state, ideasIsLoading) => {
  state.ideasIsLoading = ideasIsLoading
}
const CONTACT_RESULT_UPDATED = (state, contactFormResult) => {
  state.contactFormResult = contactFormResult
}
const FRESH_RESULT_UPDATED = (state, freshFormResult) => {
  state.freshFormResult = freshFormResult
}
const FRESH_RESULT_UPDATED2 = (state, freshFormResult) => {
  state.freshFormResult2 = freshFormResult
}
const VOLUNTEERING_UPDATED = (state, page) => {
  state.volunteering = page
}
const VOLUNTEERING_IS_LOADING_UPDATED = (state, page) => {
  state.volunteeringIsLoading = page
}
const OGIMAGE_UPDATED = (state, page) => {
  state.ogimage = page
}

export {
  EVENT_UPDATED,
  EVENTS_UPDATED,
  PAGE_UPDATED,
  HOMEPAGE_UPDATED,
  CALENDARIUM_UPDATED,
  SEARCH_RESULT,
  IDEAS_UPDATED,
  ACTIVITIES_UPDATED,
  PAGE_IS_LOADING_UPDATED,
  POST_IS_LOADING_UPDATED,
  EVENTS_IS_LOADING_UPDATED,
  ACTIVITIES_IS_LOADING_UPDATED,
  SET_PAGE_TITLE,
  IDEAS_IS_LOADING_UPDATED,
  CONTACT_RESULT_UPDATED,
  FRESH_RESULT_UPDATED,
  FRESH_RESULT_UPDATED2,
  VOLUNTEERING_UPDATED,
  VOLUNTEERING_IS_LOADING_UPDATED,
  OGIMAGE_UPDATED
}
