'use strict'
const Config = {
  appTitle: 'DOK Ursynów',
  appTitleShort: 'DOK',
  appDescription: 'DOK Ursynów',
  appThemeColor: '#00dcdc',
  appBgColor: '#ffffff',
  appIcon: 'assets/app-icon.png',
  wpDomain: (process.env.NODE_ENV === 'production') ? 'https://administracja.dokursynow.pl/' : 'https://administracja.dokursynow.pl/',
  client: (process.env.NODE_ENV === 'production') ? 'https://administracja.dokursynow.pl/' : 'https://administracja.dokursynow.pl/',
  loadDbName: 'vwpLocal',
  analyticsPath: '//www.google-analytics.com/analytics.js'
}

module.exports = Config
