import * as actions from './actions'
import * as getters from './getters'
import * as mutations from './mutations'

const defaultState = {
  items: [{
    "ID": 1754,
    "post_author": "1",
    "post_date": "2018-02-19 15:21:14",
    "post_date_gmt": "2018-02-19 15:21:14",
    "post_content": " ",
    "post_title": "",
    "post_excerpt": "",
    "post_status": "publish",
    "comment_status": "closed",
    "ping_status": "closed",
    "post_password": "",
    "post_name": "1754",
    "to_ping": "",
    "pinged": "",
    "post_modified": "2018-10-01 10:21:04",
    "post_modified_gmt": "2018-10-01 09:21:04",
    "post_content_filtered": "",
    "post_parent": 0,
    "guid": "https:\/\/dokursynow.pl\/?p=1754",
    "menu_order": 1,
    "post_type": "nav_menu_item",
    "post_mime_type": "",
    "comment_count": "0",
    "filter": "raw",
    "db_id": 1754,
    "menu_item_parent": "0",
    "object_id": "574",
    "object": "page",
    "type": "post_type",
    "type_label": "Strona",
    "url": "https:\/\/dokursynow.pl\/wydarzenia\/",
    "title": "Wydarzenia",
    "target": "",
    "attr_title": "",
    "description": "",
    "classes": [""],
    "xfn": ""
  }, {
    "ID": 3382,
    "post_author": "1",
    "post_date": "2018-08-03 16:51:37",
    "post_date_gmt": "2018-08-03 15:51:37",
    "post_content": " ",
    "post_title": "",
    "post_excerpt": "",
    "post_status": "publish",
    "comment_status": "closed",
    "ping_status": "closed",
    "post_password": "",
    "post_name": "3382",
    "to_ping": "",
    "pinged": "",
    "post_modified": "2018-10-01 10:21:04",
    "post_modified_gmt": "2018-10-01 09:21:04",
    "post_content_filtered": "",
    "post_parent": 574,
    "guid": "https:\/\/dokursynow.pl\/?p=3382",
    "menu_order": 2,
    "post_type": "nav_menu_item",
    "post_mime_type": "",
    "comment_count": "0",
    "filter": "raw",
    "db_id": 3382,
    "menu_item_parent": "1754",
    "object_id": "3380",
    "object": "page",
    "type": "post_type",
    "type_label": "Strona",
    "url": "https:\/\/dokursynow.pl\/wydarzenia\/kalendarium\/",
    "title": "Kalendarium",
    "target": "",
    "attr_title": "",
    "description": "",
    "classes": [""],
    "xfn": ""
  }, {
    "ID": 2301,
    "post_author": "1",
    "post_date": "2018-03-02 20:21:01",
    "post_date_gmt": "2018-03-02 19:21:01",
    "post_content": " ",
    "post_title": "",
    "post_excerpt": "",
    "post_status": "publish",
    "comment_status": "closed",
    "ping_status": "closed",
    "post_password": "",
    "post_name": "2301",
    "to_ping": "",
    "pinged": "",
    "post_modified": "2018-10-01 10:21:04",
    "post_modified_gmt": "2018-10-01 09:21:04",
    "post_content_filtered": "",
    "post_parent": 0,
    "guid": "https:\/\/dokursynow.pl\/?p=2301",
    "menu_order": 3,
    "post_type": "nav_menu_item",
    "post_mime_type": "",
    "comment_count": "0",
    "filter": "raw",
    "db_id": 2301,
    "menu_item_parent": "1754",
    "object_id": "1759",
    "object": "page",
    "type": "post_type",
    "type_label": "Strona",
    "url": "https:\/\/dokursynow.pl\/wydarzenia\/konkursy\/",
    "title": "Konkursy",
    "target": "",
    "attr_title": "",
    "description": "",
    "classes": [""],
    "xfn": ""
  }, {
    "ID": 3086,
    "post_author": "1",
    "post_date": "2018-07-17 12:29:03",
    "post_date_gmt": "2018-07-17 11:29:03",
    "post_content": "",
    "post_title": "Galeria FOTO",
    "post_excerpt": "",
    "post_status": "publish",
    "comment_status": "closed",
    "ping_status": "closed",
    "post_password": "",
    "post_name": "3086",
    "to_ping": "",
    "pinged": "",
    "post_modified": "2018-10-01 10:21:04",
    "post_modified_gmt": "2018-10-01 09:21:04",
    "post_content_filtered": "",
    "post_parent": 574,
    "guid": "https:\/\/dokursynow.pl\/?p=3086",
    "menu_order": 4,
    "post_type": "nav_menu_item",
    "post_mime_type": "",
    "comment_count": "0",
    "filter": "raw",
    "db_id": 3086,
    "menu_item_parent": "1754",
    "object_id": "3078",
    "object": "page",
    "type": "post_type",
    "type_label": "Strona",
    "url": "https:\/\/dokursynow.pl\/wydarzenia\/galeria\/",
    "title": "Galeria FOTO",
    "target": "",
    "attr_title": "",
    "description": "",
    "classes": [""],
    "xfn": ""
  }, {
    "ID": 1778,
    "post_author": "1",
    "post_date": "2018-02-19 15:58:14",
    "post_date_gmt": "2018-02-19 15:58:14",
    "post_content": " ",
    "post_title": "",
    "post_excerpt": "",
    "post_status": "publish",
    "comment_status": "closed",
    "ping_status": "closed",
    "post_password": "",
    "post_name": "1778",
    "to_ping": "",
    "pinged": "",
    "post_modified": "2018-10-01 10:21:04",
    "post_modified_gmt": "2018-10-01 09:21:04",
    "post_content_filtered": "",
    "post_parent": 0,
    "guid": "https:\/\/dokursynow.pl\/?p=1778",
    "menu_order": 5,
    "post_type": "nav_menu_item",
    "post_mime_type": "",
    "comment_count": "0",
    "filter": "raw",
    "db_id": 1778,
    "menu_item_parent": "0",
    "object_id": "412",
    "object": "page",
    "type": "post_type",
    "type_label": "Strona",
    "url": "https:\/\/dokursynow.pl\/zajecia\/",
    "title": "Zaj\u0119cia",
    "target": "",
    "attr_title": "",
    "description": "",
    "classes": [""],
    "xfn": ""
  }, {
    "ID": 1672,
    "post_author": "1",
    "post_date": "2018-01-31 10:11:48",
    "post_date_gmt": "2018-01-31 10:11:48",
    "post_content": " ",
    "post_title": "",
    "post_excerpt": "",
    "post_status": "publish",
    "comment_status": "closed",
    "ping_status": "closed",
    "post_password": "",
    "post_name": "1672",
    "to_ping": "",
    "pinged": "",
    "post_modified": "2018-10-01 10:21:04",
    "post_modified_gmt": "2018-10-01 09:21:04",
    "post_content_filtered": "",
    "post_parent": 0,
    "guid": "https:\/\/dokursynow.pl\/?p=1672",
    "menu_order": 6,
    "post_type": "nav_menu_item",
    "post_mime_type": "",
    "comment_count": "0",
    "filter": "raw",
    "db_id": 1672,
    "menu_item_parent": "0",
    "object_id": "1118",
    "object": "page",
    "type": "post_type",
    "type_label": "Strona",
    "url": "https:\/\/dokursynow.pl\/onas\/",
    "title": "O nas",
    "target": "",
    "attr_title": "",
    "description": "",
    "classes": [""],
    "xfn": ""
  }, {
    "ID": 3098,
    "post_author": "1",
    "post_date": "2018-07-17 12:42:24",
    "post_date_gmt": "2018-07-17 11:42:24",
    "post_content": " ",
    "post_title": "",
    "post_excerpt": "",
    "post_status": "publish",
    "comment_status": "closed",
    "ping_status": "closed",
    "post_password": "",
    "post_name": "3098",
    "to_ping": "",
    "pinged": "",
    "post_modified": "2018-10-01 10:21:04",
    "post_modified_gmt": "2018-10-01 09:21:04",
    "post_content_filtered": "",
    "post_parent": 1118,
    "guid": "https:\/\/dokursynow.pl\/?p=3098",
    "menu_order": 7,
    "post_type": "nav_menu_item",
    "post_mime_type": "",
    "comment_count": "0",
    "filter": "raw",
    "db_id": 3098,
    "menu_item_parent": "1672",
    "object_id": "3088",
    "object": "page",
    "type": "post_type",
    "type_label": "Strona",
    "url": "https:\/\/dokursynow.pl\/onas\/pracownicy\/",
    "title": "Pracownicy",
    "target": "",
    "attr_title": "",
    "description": "",
    "classes": [""],
    "xfn": ""
  }, {
    "ID": 3097,
    "post_author": "1",
    "post_date": "2018-07-17 12:42:24",
    "post_date_gmt": "2018-07-17 11:42:24",
    "post_content": " ",
    "post_title": "",
    "post_excerpt": "",
    "post_status": "publish",
    "comment_status": "closed",
    "ping_status": "closed",
    "post_password": "",
    "post_name": "3097",
    "to_ping": "",
    "pinged": "",
    "post_modified": "2018-10-01 10:21:04",
    "post_modified_gmt": "2018-10-01 09:21:04",
    "post_content_filtered": "",
    "post_parent": 1118,
    "guid": "https:\/\/dokursynow.pl\/?p=3097",
    "menu_order": 8,
    "post_type": "nav_menu_item",
    "post_mime_type": "",
    "comment_count": "0",
    "filter": "raw",
    "db_id": 3097,
    "menu_item_parent": "1672",
    "object_id": "3090",
    "object": "page",
    "type": "post_type",
    "type_label": "Strona",
    "url": "https:\/\/dokursynow.pl\/onas\/instruktorzy\/",
    "title": "Instruktorzy",
    "target": "",
    "attr_title": "",
    "description": "",
    "classes": [""],
    "xfn": ""
  }, {
    "ID": 3379,
    "post_author": "1",
    "post_date": "2018-08-03 16:50:31",
    "post_date_gmt": "2018-08-03 15:50:31",
    "post_content": " ",
    "post_title": "",
    "post_excerpt": "",
    "post_status": "publish",
    "comment_status": "closed",
    "ping_status": "closed",
    "post_password": "",
    "post_name": "3379",
    "to_ping": "",
    "pinged": "",
    "post_modified": "2018-10-01 10:21:04",
    "post_modified_gmt": "2018-10-01 09:21:04",
    "post_content_filtered": "",
    "post_parent": 0,
    "guid": "https:\/\/dokursynow.pl\/?p=3379",
    "menu_order": 9,
    "post_type": "nav_menu_item",
    "post_mime_type": "",
    "comment_count": "0",
    "filter": "raw",
    "db_id": 3379,
    "menu_item_parent": "0",
    "object_id": "3377",
    "object": "page",
    "type": "post_type",
    "type_label": "Strona",
    "url": "https:\/\/dokursynow.pl\/wystawy\/",
    "title": "Wystawy",
    "target": "",
    "attr_title": "",
    "description": "",
    "classes": [""],
    "xfn": ""
  }, {
    "ID": 3087,
    "post_author": "1",
    "post_date": "2018-07-17 12:29:31",
    "post_date_gmt": "2018-07-17 11:29:31",
    "post_content": " ",
    "post_title": "",
    "post_excerpt": "",
    "post_status": "publish",
    "comment_status": "closed",
    "ping_status": "closed",
    "post_password": "",
    "post_name": "3087",
    "to_ping": "",
    "pinged": "",
    "post_modified": "2018-10-01 10:21:04",
    "post_modified_gmt": "2018-10-01 09:21:04",
    "post_content_filtered": "",
    "post_parent": 0,
    "guid": "https:\/\/dokursynow.pl\/?p=3087",
    "menu_order": 10,
    "post_type": "nav_menu_item",
    "post_mime_type": "",
    "comment_count": "0",
    "filter": "raw",
    "db_id": 3087,
    "menu_item_parent": "0",
    "object_id": "1719",
    "object": "page",
    "type": "post_type",
    "type_label": "Strona",
    "url": "https:\/\/dokursynow.pl\/mal\/",
    "title": "MAL",
    "target": "",
    "attr_title": "",
    "description": "",
    "classes": [""],
    "xfn": ""
  }, {
    "ID": 3105,
    "post_author": "1",
    "post_date": "2018-07-18 17:21:23",
    "post_date_gmt": "2018-07-18 16:21:23",
    "post_content": " ",
    "post_title": "",
    "post_excerpt": "",
    "post_status": "publish",
    "comment_status": "closed",
    "ping_status": "closed",
    "post_password": "",
    "post_name": "3105",
    "to_ping": "",
    "pinged": "",
    "post_modified": "2018-10-01 10:21:04",
    "post_modified_gmt": "2018-10-01 09:21:04",
    "post_content_filtered": "",
    "post_parent": 1719,
    "guid": "https:\/\/dokursynow.pl\/?p=3105",
    "menu_order": 11,
    "post_type": "nav_menu_item",
    "post_mime_type": "",
    "comment_count": "0",
    "filter": "raw",
    "db_id": 3105,
    "menu_item_parent": "3087",
    "object_id": "3099",
    "object": "page",
    "type": "post_type",
    "type_label": "Strona",
    "url": "https:\/\/dokursynow.pl\/mal\/dzialania\/",
    "title": "Pomys\u0142y s\u0105siedzkie",
    "target": "",
    "attr_title": "",
    "description": "",
    "classes": [""],
    "xfn": ""
  }, {
    "ID": 3104,
    "post_author": "1",
    "post_date": "2018-07-18 17:21:23",
    "post_date_gmt": "2018-07-18 16:21:23",
    "post_content": " ",
    "post_title": "",
    "post_excerpt": "",
    "post_status": "publish",
    "comment_status": "closed",
    "ping_status": "closed",
    "post_password": "",
    "post_name": "3104",
    "to_ping": "",
    "pinged": "",
    "post_modified": "2018-10-01 10:21:04",
    "post_modified_gmt": "2018-10-01 09:21:04",
    "post_content_filtered": "",
    "post_parent": 1719,
    "guid": "https:\/\/dokursynow.pl\/?p=3104",
    "menu_order": 12,
    "post_type": "nav_menu_item",
    "post_mime_type": "",
    "comment_count": "0",
    "filter": "raw",
    "db_id": 3104,
    "menu_item_parent": "3087",
    "object_id": "3101",
    "object": "page",
    "type": "post_type",
    "type_label": "Strona",
    "url": "https:\/\/dokursynow.pl\/mal\/galerie-mal\/",
    "title": "Galeria MAL",
    "target": "",
    "attr_title": "",
    "description": "",
    "classes": [""],
    "xfn": ""
  }, {
    "ID": 3096,
    "post_author": "1",
    "post_date": "2018-07-17 12:42:24",
    "post_date_gmt": "2018-07-17 11:42:24",
    "post_content": " ",
    "post_title": "",
    "post_excerpt": "",
    "post_status": "publish",
    "comment_status": "closed",
    "ping_status": "closed",
    "post_password": "",
    "post_name": "3096",
    "to_ping": "",
    "pinged": "",
    "post_modified": "2018-10-01 10:21:04",
    "post_modified_gmt": "2018-10-01 09:21:04",
    "post_content_filtered": "",
    "post_parent": 0,
    "guid": "https:\/\/dokursynow.pl\/?p=3096",
    "menu_order": 13,
    "post_type": "nav_menu_item",
    "post_mime_type": "",
    "comment_count": "0",
    "filter": "raw",
    "db_id": 3096,
    "menu_item_parent": "0",
    "object_id": "3094",
    "object": "page",
    "type": "post_type",
    "type_label": "Strona",
    "url": "https:\/\/dokursynow.pl\/wolontariat\/",
    "title": "Wolontariat",
    "target": "",
    "attr_title": "",
    "description": "",
    "classes": [""],
    "xfn": ""
  }, {
    "ID": 4435,
    "post_author": "1",
    "post_date": "2018-10-01 10:21:05",
    "post_date_gmt": "2018-10-01 09:21:05",
    "post_content": " ",
    "post_title": "",
    "post_excerpt": "",
    "post_status": "publish",
    "comment_status": "closed",
    "ping_status": "closed",
    "post_password": "",
    "post_name": "4435",
    "to_ping": "",
    "pinged": "",
    "post_modified": "2018-10-01 10:21:05",
    "post_modified_gmt": "2018-10-01 09:21:05",
    "post_content_filtered": "",
    "post_parent": 3094,
    "guid": "https:\/\/dokursynow.pl\/?p=4435",
    "menu_order": 14,
    "post_type": "nav_menu_item",
    "post_mime_type": "",
    "comment_count": "0",
    "filter": "raw",
    "db_id": 4435,
    "menu_item_parent": "3096",
    "object_id": "4432",
    "object": "page",
    "type": "post_type",
    "type_label": "Strona",
    "url": "https:\/\/dokursynow.pl\/wolontariat\/aktualnosci\/",
    "title": "Aktualno\u015bci",
    "target": "",
    "attr_title": "",
    "description": "",
    "classes": [""],
    "xfn": ""
  }, {
    "ID": 1674,
    "post_author": "1",
    "post_date": "2018-01-31 10:11:48",
    "post_date_gmt": "2018-01-31 10:11:48",
    "post_content": " ",
    "post_title": "",
    "post_excerpt": "",
    "post_status": "publish",
    "comment_status": "closed",
    "ping_status": "closed",
    "post_password": "",
    "post_name": "1674",
    "to_ping": "",
    "pinged": "",
    "post_modified": "2018-10-01 10:21:05",
    "post_modified_gmt": "2018-10-01 09:21:05",
    "post_content_filtered": "",
    "post_parent": 0,
    "guid": "https:\/\/dokursynow.pl\/?p=1674",
    "menu_order": 15,
    "post_type": "nav_menu_item",
    "post_mime_type": "",
    "comment_count": "0",
    "filter": "raw",
    "db_id": 1674,
    "menu_item_parent": "0",
    "object_id": "713",
    "object": "page",
    "type": "post_type",
    "type_label": "Strona",
    "url": "https:\/\/dokursynow.pl\/kontakt\/",
    "title": "Kontakt",
    "target": "",
    "attr_title": "",
    "description": "",
    "classes": [""],
    "xfn": ""
  }],
  itemsFooter: [{
    "ID": 3106,
    "post_author": "1",
    "post_date": "2018-07-18 18:46:00",
    "post_date_gmt": "2018-07-18 17:46:00",
    "post_content": "",
    "post_title": "Dok Ursyn\u00f3w",
    "post_excerpt": "",
    "post_status": "publish",
    "comment_status": "closed",
    "ping_status": "closed",
    "post_password": "",
    "post_name": "dok-ursynow",
    "to_ping": "",
    "pinged": "",
    "post_modified": "2018-09-14 13:04:57",
    "post_modified_gmt": "2018-09-14 12:04:57",
    "post_content_filtered": "",
    "post_parent": 0,
    "guid": "https:\/\/dokursynow.pl\/?p=3106",
    "menu_order": 1,
    "post_type": "nav_menu_item",
    "post_mime_type": "",
    "comment_count": "0",
    "filter": "raw",
    "db_id": 3106,
    "menu_item_parent": "0",
    "object_id": "3106",
    "object": "custom",
    "type": "custom",
    "type_label": "W\u0142asny odno\u015bnik",
    "title": "Dok Ursyn\u00f3w",
    "url": "http:\/\/#",
    "target": "",
    "attr_title": "",
    "description": "",
    "classes": [""],
    "xfn": ""
  }, {
    "ID": 3119,
    "post_author": "1",
    "post_date": "2018-07-18 18:46:00",
    "post_date_gmt": "2018-07-18 17:46:00",
    "post_content": " ",
    "post_title": "",
    "post_excerpt": "",
    "post_status": "publish",
    "comment_status": "closed",
    "ping_status": "closed",
    "post_password": "",
    "post_name": "3119",
    "to_ping": "",
    "pinged": "",
    "post_modified": "2018-09-14 13:04:57",
    "post_modified_gmt": "2018-09-14 12:04:57",
    "post_content_filtered": "",
    "post_parent": 0,
    "guid": "https:\/\/dokursynow.pl\/?p=3119",
    "menu_order": 2,
    "post_type": "nav_menu_item",
    "post_mime_type": "",
    "comment_count": "0",
    "filter": "raw",
    "db_id": 3119,
    "menu_item_parent": "3106",
    "object_id": "574",
    "object": "page",
    "type": "post_type",
    "type_label": "Strona",
    "url": "https:\/\/dokursynow.pl\/wydarzenia\/",
    "title": "Wydarzenia",
    "target": "",
    "attr_title": "",
    "description": "",
    "classes": [""],
    "xfn": ""
  }, {
    "ID": 3122,
    "post_author": "1",
    "post_date": "2018-07-18 18:46:00",
    "post_date_gmt": "2018-07-18 17:46:00",
    "post_content": " ",
    "post_title": "",
    "post_excerpt": "",
    "post_status": "publish",
    "comment_status": "closed",
    "ping_status": "closed",
    "post_password": "",
    "post_name": "3122",
    "to_ping": "",
    "pinged": "",
    "post_modified": "2018-09-14 13:04:57",
    "post_modified_gmt": "2018-09-14 12:04:57",
    "post_content_filtered": "",
    "post_parent": 574,
    "guid": "https:\/\/dokursynow.pl\/?p=3122",
    "menu_order": 3,
    "post_type": "nav_menu_item",
    "post_mime_type": "",
    "comment_count": "0",
    "filter": "raw",
    "db_id": 3122,
    "menu_item_parent": "3106",
    "object_id": "3078",
    "object": "page",
    "type": "post_type",
    "type_label": "Strona",
    "url": "https:\/\/dokursynow.pl\/wydarzenia\/galeria\/",
    "title": "Galeria",
    "target": "",
    "attr_title": "",
    "description": "",
    "classes": [""],
    "xfn": ""
  }, {
    "ID": 3123,
    "post_author": "1",
    "post_date": "2018-07-18 18:46:00",
    "post_date_gmt": "2018-07-18 17:46:00",
    "post_content": " ",
    "post_title": "",
    "post_excerpt": "",
    "post_status": "publish",
    "comment_status": "closed",
    "ping_status": "closed",
    "post_password": "",
    "post_name": "3123",
    "to_ping": "",
    "pinged": "",
    "post_modified": "2018-09-14 13:04:57",
    "post_modified_gmt": "2018-09-14 12:04:57",
    "post_content_filtered": "",
    "post_parent": 0,
    "guid": "https:\/\/dokursynow.pl\/?p=3123",
    "menu_order": 4,
    "post_type": "nav_menu_item",
    "post_mime_type": "",
    "comment_count": "0",
    "filter": "raw",
    "db_id": 3123,
    "menu_item_parent": "3106",
    "object_id": "412",
    "object": "page",
    "type": "post_type",
    "type_label": "Strona",
    "url": "https:\/\/dokursynow.pl\/zajecia\/",
    "title": "Zaj\u0119cia",
    "target": "",
    "attr_title": "",
    "description": "",
    "classes": [""],
    "xfn": ""
  }, {
    "ID": 3217,
    "post_author": "1",
    "post_date": "2018-07-20 19:08:30",
    "post_date_gmt": "2018-07-20 18:08:30",
    "post_content": " ",
    "post_title": "",
    "post_excerpt": "",
    "post_status": "publish",
    "comment_status": "closed",
    "ping_status": "closed",
    "post_password": "",
    "post_name": "3217",
    "to_ping": "",
    "pinged": "",
    "post_modified": "2018-09-14 13:04:57",
    "post_modified_gmt": "2018-09-14 12:04:57",
    "post_content_filtered": "",
    "post_parent": 0,
    "guid": "https:\/\/dokursynow.pl\/?p=3217",
    "menu_order": 5,
    "post_type": "nav_menu_item",
    "post_mime_type": "",
    "comment_count": "0",
    "filter": "raw",
    "db_id": 3217,
    "menu_item_parent": "3106",
    "object_id": "1759",
    "object": "page",
    "type": "post_type",
    "type_label": "Strona",
    "url": "https:\/\/dokursynow.pl\/wydarzenia\/konkursy\/",
    "title": "Konkursy",
    "target": "",
    "attr_title": "",
    "description": "",
    "classes": [""],
    "xfn": ""
  }, {
    "ID": 3468,
    "post_author": "1",
    "post_date": "2018-08-04 15:17:45",
    "post_date_gmt": "2018-08-04 14:17:45",
    "post_content": " ",
    "post_title": "",
    "post_excerpt": "",
    "post_status": "publish",
    "comment_status": "closed",
    "ping_status": "closed",
    "post_password": "",
    "post_name": "3468",
    "to_ping": "",
    "pinged": "",
    "post_modified": "2018-09-14 13:04:57",
    "post_modified_gmt": "2018-09-14 12:04:57",
    "post_content_filtered": "",
    "post_parent": 0,
    "guid": "https:\/\/dokursynow.pl\/?p=3468",
    "menu_order": 6,
    "post_type": "nav_menu_item",
    "post_mime_type": "",
    "comment_count": "0",
    "filter": "raw",
    "db_id": 3468,
    "menu_item_parent": "3106",
    "object_id": "3377",
    "object": "page",
    "type": "post_type",
    "type_label": "Strona",
    "url": "https:\/\/dokursynow.pl\/wystawy\/",
    "title": "Wystawy",
    "target": "",
    "attr_title": "",
    "description": "",
    "classes": [""],
    "xfn": ""
  }, {
    "ID": 3172,
    "post_author": "1",
    "post_date": "2018-07-20 10:29:07",
    "post_date_gmt": "2018-07-20 09:29:07",
    "post_content": " ",
    "post_title": "",
    "post_excerpt": "",
    "post_status": "publish",
    "comment_status": "closed",
    "ping_status": "closed",
    "post_password": "",
    "post_name": "3172",
    "to_ping": "",
    "pinged": "",
    "post_modified": "2018-09-14 13:04:57",
    "post_modified_gmt": "2018-09-14 12:04:57",
    "post_content_filtered": "",
    "post_parent": 0,
    "guid": "https:\/\/dokursynow.pl\/?p=3172",
    "menu_order": 7,
    "post_type": "nav_menu_item",
    "post_mime_type": "",
    "comment_count": "0",
    "filter": "raw",
    "db_id": 3172,
    "menu_item_parent": "3106",
    "object_id": "3161",
    "object": "page",
    "type": "post_type",
    "type_label": "Strona",
    "url": "https:\/\/dokursynow.pl\/wynajem\/",
    "title": "Wynajem",
    "target": "",
    "attr_title": "",
    "description": "",
    "classes": [""],
    "xfn": ""
  }, {
    "ID": 3115,
    "post_author": "1",
    "post_date": "2018-07-18 18:46:00",
    "post_date_gmt": "2018-07-18 17:46:00",
    "post_content": " ",
    "post_title": "",
    "post_excerpt": "",
    "post_status": "publish",
    "comment_status": "closed",
    "ping_status": "closed",
    "post_password": "",
    "post_name": "3115",
    "to_ping": "",
    "pinged": "",
    "post_modified": "2018-09-14 13:04:57",
    "post_modified_gmt": "2018-09-14 12:04:57",
    "post_content_filtered": "",
    "post_parent": 1118,
    "guid": "https:\/\/dokursynow.pl\/?p=3115",
    "menu_order": 8,
    "post_type": "nav_menu_item",
    "post_mime_type": "",
    "comment_count": "0",
    "filter": "raw",
    "db_id": 3115,
    "menu_item_parent": "3106",
    "object_id": "3094",
    "object": "page",
    "type": "post_type",
    "type_label": "Strona",
    "url": "https:\/\/dokursynow.pl\/wolontariat\/",
    "title": "Wolontariat",
    "target": "",
    "attr_title": "",
    "description": "",
    "classes": [""],
    "xfn": ""
  }, {
    "ID": 3107,
    "post_author": "1",
    "post_date": "2018-07-18 18:46:00",
    "post_date_gmt": "2018-07-18 17:46:00",
    "post_content": "",
    "post_title": "O nas",
    "post_excerpt": "",
    "post_status": "publish",
    "comment_status": "closed",
    "ping_status": "closed",
    "post_password": "",
    "post_name": "o-nas",
    "to_ping": "",
    "pinged": "",
    "post_modified": "2018-09-14 13:04:57",
    "post_modified_gmt": "2018-09-14 12:04:57",
    "post_content_filtered": "",
    "post_parent": 0,
    "guid": "https:\/\/dokursynow.pl\/?p=3107",
    "menu_order": 9,
    "post_type": "nav_menu_item",
    "post_mime_type": "",
    "comment_count": "0",
    "filter": "raw",
    "db_id": 3107,
    "menu_item_parent": "0",
    "object_id": "3107",
    "object": "custom",
    "type": "custom",
    "type_label": "W\u0142asny odno\u015bnik",
    "title": "O nas",
    "url": "http:\/\/#",
    "target": "",
    "attr_title": "",
    "description": "",
    "classes": [""],
    "xfn": ""
  }, {
    "ID": 3114,
    "post_author": "1",
    "post_date": "2018-07-18 18:46:00",
    "post_date_gmt": "2018-07-18 17:46:00",
    "post_content": " ",
    "post_title": "",
    "post_excerpt": "",
    "post_status": "publish",
    "comment_status": "closed",
    "ping_status": "closed",
    "post_password": "",
    "post_name": "3114",
    "to_ping": "",
    "pinged": "",
    "post_modified": "2018-09-14 13:04:57",
    "post_modified_gmt": "2018-09-14 12:04:57",
    "post_content_filtered": "",
    "post_parent": 0,
    "guid": "https:\/\/dokursynow.pl\/?p=3114",
    "menu_order": 10,
    "post_type": "nav_menu_item",
    "post_mime_type": "",
    "comment_count": "0",
    "filter": "raw",
    "db_id": 3114,
    "menu_item_parent": "3107",
    "object_id": "1118",
    "object": "page",
    "type": "post_type",
    "type_label": "Strona",
    "url": "https:\/\/dokursynow.pl\/onas\/",
    "title": "O nas",
    "target": "",
    "attr_title": "",
    "description": "",
    "classes": [""],
    "xfn": ""
  }, {
    "ID": 3111,
    "post_author": "1",
    "post_date": "2018-07-18 18:46:00",
    "post_date_gmt": "2018-07-18 17:46:00",
    "post_content": " ",
    "post_title": "",
    "post_excerpt": "",
    "post_status": "publish",
    "comment_status": "closed",
    "ping_status": "closed",
    "post_password": "",
    "post_name": "3111",
    "to_ping": "",
    "pinged": "",
    "post_modified": "2018-09-14 13:04:57",
    "post_modified_gmt": "2018-09-14 12:04:57",
    "post_content_filtered": "",
    "post_parent": 0,
    "guid": "https:\/\/dokursynow.pl\/?p=3111",
    "menu_order": 11,
    "post_type": "nav_menu_item",
    "post_mime_type": "",
    "comment_count": "0",
    "filter": "raw",
    "db_id": 3111,
    "menu_item_parent": "3107",
    "object_id": "1719",
    "object": "page",
    "type": "post_type",
    "type_label": "Strona",
    "url": "https:\/\/dokursynow.pl\/mal\/",
    "title": "MAL",
    "target": "",
    "attr_title": "",
    "description": "",
    "classes": [""],
    "xfn": ""
  }, {
    "ID": 3112,
    "post_author": "1",
    "post_date": "2018-07-18 18:46:00",
    "post_date_gmt": "2018-07-18 17:46:00",
    "post_content": " ",
    "post_title": "",
    "post_excerpt": "",
    "post_status": "publish",
    "comment_status": "closed",
    "ping_status": "closed",
    "post_password": "",
    "post_name": "3112",
    "to_ping": "",
    "pinged": "",
    "post_modified": "2018-09-14 13:04:57",
    "post_modified_gmt": "2018-09-14 12:04:57",
    "post_content_filtered": "",
    "post_parent": 1719,
    "guid": "https:\/\/dokursynow.pl\/?p=3112",
    "menu_order": 12,
    "post_type": "nav_menu_item",
    "post_mime_type": "",
    "comment_count": "0",
    "filter": "raw",
    "db_id": 3112,
    "menu_item_parent": "3107",
    "object_id": "3101",
    "object": "page",
    "type": "post_type",
    "type_label": "Strona",
    "url": "https:\/\/dokursynow.pl\/mal\/galerie-mal\/",
    "title": "Galeria MAL",
    "target": "",
    "attr_title": "",
    "description": "",
    "classes": [""],
    "xfn": ""
  }, {
    "ID": 3113,
    "post_author": "1",
    "post_date": "2018-07-18 18:46:00",
    "post_date_gmt": "2018-07-18 17:46:00",
    "post_content": " ",
    "post_title": "",
    "post_excerpt": "",
    "post_status": "publish",
    "comment_status": "closed",
    "ping_status": "closed",
    "post_password": "",
    "post_name": "3113",
    "to_ping": "",
    "pinged": "",
    "post_modified": "2018-09-14 13:04:57",
    "post_modified_gmt": "2018-09-14 12:04:57",
    "post_content_filtered": "",
    "post_parent": 1719,
    "guid": "https:\/\/dokursynow.pl\/?p=3113",
    "menu_order": 13,
    "post_type": "nav_menu_item",
    "post_mime_type": "",
    "comment_count": "0",
    "filter": "raw",
    "db_id": 3113,
    "menu_item_parent": "3107",
    "object_id": "3099",
    "object": "page",
    "type": "post_type",
    "type_label": "Strona",
    "url": "https:\/\/dokursynow.pl\/mal\/dzialania\/",
    "title": "Pomys\u0142y s\u0105siedzkie",
    "target": "",
    "attr_title": "",
    "description": "",
    "classes": [""],
    "xfn": ""
  }, {
    "ID": 3116,
    "post_author": "1",
    "post_date": "2018-07-18 18:46:00",
    "post_date_gmt": "2018-07-18 17:46:00",
    "post_content": " ",
    "post_title": "",
    "post_excerpt": "",
    "post_status": "publish",
    "comment_status": "closed",
    "ping_status": "closed",
    "post_password": "",
    "post_name": "3116",
    "to_ping": "",
    "pinged": "",
    "post_modified": "2018-09-14 13:04:57",
    "post_modified_gmt": "2018-09-14 12:04:57",
    "post_content_filtered": "",
    "post_parent": 1118,
    "guid": "https:\/\/dokursynow.pl\/?p=3116",
    "menu_order": 14,
    "post_type": "nav_menu_item",
    "post_mime_type": "",
    "comment_count": "0",
    "filter": "raw",
    "db_id": 3116,
    "menu_item_parent": "3107",
    "object_id": "3090",
    "object": "page",
    "type": "post_type",
    "type_label": "Strona",
    "url": "https:\/\/dokursynow.pl\/onas\/instruktorzy\/",
    "title": "Instruktorzy",
    "target": "",
    "attr_title": "",
    "description": "",
    "classes": [""],
    "xfn": ""
  }, {
    "ID": 3117,
    "post_author": "1",
    "post_date": "2018-07-18 18:46:00",
    "post_date_gmt": "2018-07-18 17:46:00",
    "post_content": " ",
    "post_title": "",
    "post_excerpt": "",
    "post_status": "publish",
    "comment_status": "closed",
    "ping_status": "closed",
    "post_password": "",
    "post_name": "3117",
    "to_ping": "",
    "pinged": "",
    "post_modified": "2018-09-14 13:04:57",
    "post_modified_gmt": "2018-09-14 12:04:57",
    "post_content_filtered": "",
    "post_parent": 1118,
    "guid": "https:\/\/dokursynow.pl\/?p=3117",
    "menu_order": 15,
    "post_type": "nav_menu_item",
    "post_mime_type": "",
    "comment_count": "0",
    "filter": "raw",
    "db_id": 3117,
    "menu_item_parent": "3107",
    "object_id": "3088",
    "object": "page",
    "type": "post_type",
    "type_label": "Strona",
    "url": "https:\/\/dokursynow.pl\/onas\/pracownicy\/",
    "title": "Pracownicy",
    "target": "",
    "attr_title": "",
    "description": "",
    "classes": [""],
    "xfn": ""
  }, {
    "ID": 3108,
    "post_author": "1",
    "post_date": "2018-07-18 18:46:00",
    "post_date_gmt": "2018-07-18 17:46:00",
    "post_content": "",
    "post_title": "Inne",
    "post_excerpt": "",
    "post_status": "publish",
    "comment_status": "closed",
    "ping_status": "closed",
    "post_password": "",
    "post_name": "inne",
    "to_ping": "",
    "pinged": "",
    "post_modified": "2018-09-14 13:04:57",
    "post_modified_gmt": "2018-09-14 12:04:57",
    "post_content_filtered": "",
    "post_parent": 0,
    "guid": "https:\/\/dokursynow.pl\/?p=3108",
    "menu_order": 16,
    "post_type": "nav_menu_item",
    "post_mime_type": "",
    "comment_count": "0",
    "filter": "raw",
    "db_id": 3108,
    "menu_item_parent": "0",
    "object_id": "3108",
    "object": "custom",
    "type": "custom",
    "type_label": "W\u0142asny odno\u015bnik",
    "title": "Inne",
    "url": "http:\/\/#",
    "target": "",
    "attr_title": "",
    "description": "",
    "classes": [""],
    "xfn": ""
  }, {
    "ID": 3118,
    "post_author": "1",
    "post_date": "2018-07-18 18:46:00",
    "post_date_gmt": "2018-07-18 17:46:00",
    "post_content": " ",
    "post_title": "",
    "post_excerpt": "",
    "post_status": "publish",
    "comment_status": "closed",
    "ping_status": "closed",
    "post_password": "",
    "post_name": "3118",
    "to_ping": "",
    "pinged": "",
    "post_modified": "2018-09-14 13:04:57",
    "post_modified_gmt": "2018-09-14 12:04:57",
    "post_content_filtered": "",
    "post_parent": 0,
    "guid": "https:\/\/dokursynow.pl\/?p=3118",
    "menu_order": 17,
    "post_type": "nav_menu_item",
    "post_mime_type": "",
    "comment_count": "0",
    "filter": "raw",
    "db_id": 3118,
    "menu_item_parent": "3108",
    "object_id": "713",
    "object": "page",
    "type": "post_type",
    "type_label": "Strona",
    "url": "https:\/\/dokursynow.pl\/kontakt\/",
    "title": "Kontakt",
    "target": "",
    "attr_title": "",
    "description": "",
    "classes": [""],
    "xfn": ""
  }, {
    "ID": 4227,
    "post_author": "1",
    "post_date": "2018-09-14 13:04:57",
    "post_date_gmt": "2018-09-14 12:04:57",
    "post_content": " ",
    "post_title": "",
    "post_excerpt": "",
    "post_status": "publish",
    "comment_status": "closed",
    "ping_status": "closed",
    "post_password": "",
    "post_name": "4227",
    "to_ping": "",
    "pinged": "",
    "post_modified": "2018-09-14 13:04:57",
    "post_modified_gmt": "2018-09-14 12:04:57",
    "post_content_filtered": "",
    "post_parent": 0,
    "guid": "https:\/\/dokursynow.pl\/?p=4227",
    "menu_order": 18,
    "post_type": "nav_menu_item",
    "post_mime_type": "",
    "comment_count": "0",
    "filter": "raw",
    "db_id": 4227,
    "menu_item_parent": "3108",
    "object_id": "4211",
    "object": "page",
    "type": "post_type",
    "type_label": "Strona",
    "url": "https:\/\/dokursynow.pl\/regulaminy\/",
    "title": "Regulaminy",
    "target": "",
    "attr_title": "",
    "description": "",
    "classes": [""],
    "xfn": ""
  }, {
    "ID": 3153,
    "post_author": "1",
    "post_date": "2018-07-20 08:59:43",
    "post_date_gmt": "2018-07-20 07:59:43",
    "post_content": " ",
    "post_title": "",
    "post_excerpt": "",
    "post_status": "publish",
    "comment_status": "closed",
    "ping_status": "closed",
    "post_password": "",
    "post_name": "3153",
    "to_ping": "",
    "pinged": "",
    "post_modified": "2018-09-14 13:04:57",
    "post_modified_gmt": "2018-09-14 12:04:57",
    "post_content_filtered": "",
    "post_parent": 0,
    "guid": "https:\/\/dokursynow.pl\/?p=3153",
    "menu_order": 19,
    "post_type": "nav_menu_item",
    "post_mime_type": "",
    "comment_count": "0",
    "filter": "raw",
    "db_id": 3153,
    "menu_item_parent": "3108",
    "object_id": "3126",
    "object": "page",
    "type": "post_type",
    "type_label": "Strona",
    "url": "https:\/\/dokursynow.pl\/ksiazka\/",
    "title": "Ksi\u0105\u017cka",
    "target": "",
    "attr_title": "",
    "description": "",
    "classes": [""],
    "xfn": ""
  }, {
    "ID": 3109,
    "post_author": "1",
    "post_date": "2018-07-18 18:46:00",
    "post_date_gmt": "2018-07-18 17:46:00",
    "post_content": " ",
    "post_title": "",
    "post_excerpt": "",
    "post_status": "publish",
    "comment_status": "closed",
    "ping_status": "closed",
    "post_password": "",
    "post_name": "3109",
    "to_ping": "",
    "pinged": "",
    "post_modified": "2018-09-14 13:04:57",
    "post_modified_gmt": "2018-09-14 12:04:57",
    "post_content_filtered": "",
    "post_parent": 0,
    "guid": "https:\/\/dokursynow.pl\/?p=3109",
    "menu_order": 20,
    "post_type": "nav_menu_item",
    "post_mime_type": "",
    "comment_count": "0",
    "filter": "raw",
    "db_id": 3109,
    "menu_item_parent": "3108",
    "object_id": "2876",
    "object": "page",
    "type": "post_type",
    "type_label": "Strona",
    "url": "https:\/\/dokursynow.pl\/rodo\/",
    "title": "RODO",
    "target": "",
    "attr_title": "",
    "description": "",
    "classes": [""],
    "xfn": ""
  }, {
    "ID": 3215,
    "post_author": "1",
    "post_date": "2018-07-20 19:08:30",
    "post_date_gmt": "2018-07-20 18:08:30",
    "post_content": "",
    "post_title": "YouTube",
    "post_excerpt": "",
    "post_status": "publish",
    "comment_status": "closed",
    "ping_status": "closed",
    "post_password": "",
    "post_name": "youtube",
    "to_ping": "",
    "pinged": "",
    "post_modified": "2018-09-14 13:04:57",
    "post_modified_gmt": "2018-09-14 12:04:57",
    "post_content_filtered": "",
    "post_parent": 0,
    "guid": "https:\/\/dokursynow.pl\/?p=3215",
    "menu_order": 21,
    "post_type": "nav_menu_item",
    "post_mime_type": "",
    "comment_count": "0",
    "filter": "raw",
    "db_id": 3215,
    "menu_item_parent": "3108",
    "object_id": "3215",
    "object": "custom",
    "type": "custom",
    "type_label": "W\u0142asny odno\u015bnik",
    "title": "YouTube",
    "url": "https:\/\/www.youtube.com\/channel\/UCuWeOrrA1KcIw0irRVBX2vg",
    "target": "",
    "attr_title": "",
    "description": "",
    "classes": [""],
    "xfn": ""
  }, {
    "ID": 3216,
    "post_author": "1",
    "post_date": "2018-07-20 19:08:30",
    "post_date_gmt": "2018-07-20 18:08:30",
    "post_content": "",
    "post_title": "facebook",
    "post_excerpt": "",
    "post_status": "publish",
    "comment_status": "closed",
    "ping_status": "closed",
    "post_password": "",
    "post_name": "facebook",
    "to_ping": "",
    "pinged": "",
    "post_modified": "2018-09-14 13:04:57",
    "post_modified_gmt": "2018-09-14 12:04:57",
    "post_content_filtered": "",
    "post_parent": 0,
    "guid": "https:\/\/dokursynow.pl\/?p=3216",
    "menu_order": 22,
    "post_type": "nav_menu_item",
    "post_mime_type": "",
    "comment_count": "0",
    "filter": "raw",
    "db_id": 3216,
    "menu_item_parent": "3108",
    "object_id": "3216",
    "object": "custom",
    "type": "custom",
    "type_label": "W\u0142asny odno\u015bnik",
    "title": "facebook",
    "url": "https:\/\/pl-pl.facebook.com\/dokURSYNOW\/",
    "target": "",
    "attr_title": "",
    "description": "",
    "classes": [""],
    "xfn": ""
  }]
}

const inBrowser = typeof window !== 'undefined'

// if in browser, use pre-fetched state injected by SSR
const state = (inBrowser && window.__INITIAL_STATE__) ? window.__INITIAL_STATE__.menu : defaultState

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}