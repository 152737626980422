import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./vuex/store";
import * as VueGoogleMaps from "vue2-google-maps";
import VueHead from "vue-head";
//import "./registerServiceWorker";
import "./assets/css/dok.scss";
import "./assets/css/grid.css";
import "./assets/css/wordpress.css";
import Lightbox from "vue-pure-lightbox";
import Siema from "vue2-siema";
// import './registerServiceWorker'

Vue.use(Siema);
Vue.use(VueHead);
Vue.use(Lightbox);

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBfrTXjc-xS6Q3Kbozd6uG6WK6bN5tERU8",
  },
});
Vue.config.productionTip = false;
// if (process.env.NODE_ENV === 'development') {
//   const VueAxe = require('vue-axe').default
//   Vue.use(VueAxe)
// }

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
