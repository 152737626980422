const BLOGMENU_UPDATED = (state, menuItems) => {
  state.items = menuItems
}

const BLOGMENU_FOOTER_UPDATED = (state, menuItems) => {
  state.itemsFooter = menuItems
}

export {
  BLOGMENU_UPDATED,
  BLOGMENU_FOOTER_UPDATED
}
