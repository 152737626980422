import Config from './app.config.js'
import appCache from './app.service.cache.js'
import axios from 'axios'

let wordpressService = {
  cacheRequest(path, cacheTime) {
    return new Promise((resolve, reject) => {
      appCache.get(path, cacheTime)
        .then(response => { resolve(response) })
        .catch(err => { reject(err) })
    })
  },
  getMenuByName(menuName) {
    return new Promise((resolve, reject) => {
      this.cacheRequest(Config.wpDomain + `wp-json/dokroutes/${menuName}`, 0)
        .then(response => { resolve(response.data) })
        .catch(error => { reject(error) })
    })
  },
  getPage(pageId, pageSlug) {
    return new Promise((resolve, reject) => {
      let path = Config.wpDomain + `wp-json/wp/v2/pages/${pageId}`
      if (!pageId && pageSlug) {
        path = Config.wpDomain + `wp-json/dokroutes/get_page_by_slug/${pageSlug}`
      }
      this.cacheRequest(path, 0)
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  },
  getPost(pageId, pageSlug) {
    return new Promise((resolve, reject) => {
      let path = Config.wpDomain + `wp-json/wp/v2/pages/${pageId}`
      if (!pageId && pageSlug) {
        path = Config.wpDomain + `wp-json/dokroutes/get_post_by_slug/${pageSlug}`
      }
      this.cacheRequest(path, 0)
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  },
  getHomepage() {
    return new Promise((resolve, reject) => {
      let path = Config.wpDomain + `wp-json/dokroutes/homepage`
      this.cacheRequest(path, 0)
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  },
  getEvents(getConditions) {
    return new Promise((resolve, reject) => {
      let path = Config.wpDomain + `wp-json/dokroutes/events?page=${getConditions.page}&archive=${getConditions.archive}&category=${getConditions.category}`
      this.cacheRequest(path, 0)
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  },
  getCalendarium() {
    return new Promise((resolve, reject) => {
      let path = Config.wpDomain + `wp-json/dokroutes/calendarium`
      this.cacheRequest(path, 0)
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  },
  searchFor(searchTerm) {
    return new Promise((resolve, reject) => {
      let path = Config.wpDomain + `wp-json/dokroutes/search?s=` + searchTerm
      this.cacheRequest(path, 0)
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  },
  getIdeas(getConditions) {
    return new Promise((resolve, reject) => {
      let path = Config.wpDomain + `wp-json/dokroutes/ideas?page=${getConditions.page}&archive=${getConditions.archive}`
      this.cacheRequest(path, 0)
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  },
  getActivities(getConditions) {
    return new Promise((resolve, reject) => {
      let path = Config.wpDomain + `wp-json/dokroutes/activities?page=${getConditions.page}&category=${getConditions.category}&day=${getConditions.day}`
      this.cacheRequest(path, 0)
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  },
  contactForm(contactForm) {
    return new Promise((resolve, reject) => {
      axios.post(Config.wpDomain + `wp-json/dokroutes/contactform`, {
        contactForm
      })
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  },
  freshForm(freshForm) {
    return new Promise((resolve, reject) => {
      axios.post(Config.wpDomain + `wp-json/dokroutes/freshmailme`, {
        freshForm
      })
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  },
  freshForm2(freshForm) {
    return new Promise((resolve, reject) => {
      axios.post(Config.wpDomain + `wp-json/dokroutes/freshmailme2`, {
        freshForm
      })
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  },
  getVolunteering(getConditions) {
    return new Promise((resolve, reject) => {
      let path = Config.wpDomain + `wp-json/dokroutes/volunteering?page=${getConditions.page}&archive=${getConditions.archive}`
      this.cacheRequest(path, 0)
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  },
  goToEdit(slug) {
    return new Promise((resolve, reject) => {
      let path = Config.wpDomain + `wp-json/dokroutes/goToEdit?page=${slug}`
      this.cacheRequest(path, 0)
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  }
}

export default wordpressService
