import wordpressService from '../../../app.service'
const getMenu = ({commit, state}, menuName) => {
  return wordpressService.getMenuByName(menuName).then((menu) => {
    if (menuName === 'menu_main') {
      commit('BLOGMENU_UPDATED', menu)
    } else {
      commit('BLOGMENU_FOOTER_UPDATED', menu)
    }
  }).catch(error => {
    console.log(error)
  })
}

export {
  getMenu
}
