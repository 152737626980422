import wordpressService from '../../../app.service'

const getPage = ({ commit }, slug) => {
  commit('PAGE_IS_LOADING_UPDATED', true)
  return wordpressService.getPage(null, slug).then((page) => {
    page = JSON.parse(page)
    page.POST_CONTENT = page.POST_CONTENT !== '' ? page.POST_CONTENT : '404'
    commit('PAGE_UPDATED', page.POST_CONTENT)
    commit('PAGE_IS_LOADING_UPDATED', false)
    commit('SET_PAGE_TITLE', page.POST_TITLE) // pobrac tytul strony
    commit('OGIMAGE_UPDATED', '/img/icons/android-icon-512x512.png')
  }).catch(error => {
    console.log(error)
  })
}
const getHomepage = ({ commit }) => {
  return wordpressService.getHomepage().then((page) => {
    commit('HOMEPAGE_UPDATED', JSON.parse(page))
    commit('SET_PAGE_TITLE', 'DOK Ursynów')
    commit('OGIMAGE_UPDATED', '/img/icons/android-icon-512x512.png')
  }).catch(error => {
    console.log(error)
  })
}
const getPost = ({ commit }, slug) => {
  commit('POST_IS_LOADING_UPDATED', true)
  return wordpressService.getPost(null, slug).then((page) => {
    page = JSON.parse(page)
    page.POST_CONTENT = page.POST_CONTENT !== '' ? page.POST_CONTENT : '404'
    commit('EVENT_UPDATED', page.POST_CONTENT)
    commit('OGIMAGE_UPDATED', (page.POST_IMG === false ? '/img/icons/android-icon-512x512.png' : page.POST_IMG))
    commit('POST_IS_LOADING_UPDATED', false)
    commit('SET_PAGE_TITLE', page.POST_TITLE) // pobrac tytul strony
  }).catch(error => {
    console.log(error)
  })
}
const getEvents = ({ commit }, getConditions) => {
  commit('EVENTS_IS_LOADING_UPDATED', true)
  return wordpressService.getEvents(getConditions).then((page) => {
    commit('EVENTS_UPDATED', page)
    commit('EVENTS_IS_LOADING_UPDATED', false)
    commit('SET_PAGE_TITLE', 'Wydarzenia') // pobrac tytul strony
    commit('OGIMAGE_UPDATED', '/img/icons/android-icon-512x512.png')
  }).catch(error => {
    console.log(error)
  })
}
const getCalendarium = ({ commit }, slug) => {
  return wordpressService.getCalendarium(null, slug).then((page) => {
    commit('CALENDARIUM_UPDATED', page)
    commit('SET_PAGE_TITLE', 'Kalendarium') // pobrac tytul strony
    commit('OGIMAGE_UPDATED', '/img/icons/android-icon-512x512.png')
  }).catch(error => {
    console.log(error)
  })
}
const searchFor = ({ commit }, searchTerm) => {
  commit('SEARCH_RESULT', [{
    title: '<div class="text-input__loading"><div class="text-input__loading--line"></div></div>',
    link: '#'
  }])
  return wordpressService.searchFor(searchTerm).then((searchResult) => {
    commit('SEARCH_RESULT', searchResult)
    commit('SET_PAGE_TITLE', 'Wyniki wyszukiwania') // pobrac tytul strony
    commit('OGIMAGE_UPDATED', '/img/icons/android-icon-512x512.png')
  }).catch(error => {
    console.log(error)
  })
}
const getIdeas = ({ commit }, getConditions) => {
  commit('IDEAS_IS_LOADING_UPDATED', true)
  return wordpressService.getIdeas(getConditions).then((page) => {
    commit('IDEAS_UPDATED', page)
    commit('IDEAS_IS_LOADING_UPDATED', false)
    commit('SET_PAGE_TITLE', 'Pomysły sąsiedzkie') // pobrac tytul strony
    commit('OGIMAGE_UPDATED', '/img/icons/android-icon-512x512.png')
  }).catch(error => {
    console.log(error)
  })
}
const getActivities = ({ commit }, getConditions) => {
  commit('ACTIVITIES_IS_LOADING_UPDATED', true)
  return wordpressService.getActivities(getConditions).then((page) => {
    commit('ACTIVITIES_UPDATED', page)
    commit('ACTIVITIES_IS_LOADING_UPDATED', false)
    commit('SET_PAGE_TITLE', 'Zajęcia') // pobrac tytul strony
    commit('OGIMAGE_UPDATED', '/img/icons/android-icon-512x512.png')
  }).catch(error => {
    console.log(error)
  })
}
const getVolunteering = ({ commit }, getConditions) => {
  commit('VOLUNTEERING_IS_LOADING_UPDATED', true)
  return wordpressService.getVolunteering(getConditions).then((page) => {
    commit('VOLUNTEERING_UPDATED', page)
    commit('VOLUNTEERING_IS_LOADING_UPDATED', false)
    commit('SET_PAGE_TITLE', 'Aktualności wolontariatu') // pobrac tytul strony
    commit('OGIMAGE_UPDATED', '/img/icons/android-icon-512x512.png')
  }).catch(error => {
    console.log(error)
  })
}
const contactForm = ({ commit }, contactForm) => {
  return wordpressService.contactForm(contactForm).then((page) => {
    commit('CONTACT_RESULT_UPDATED', page)
  }).catch(error => {
    console.log(error)
  })
}
const freshForm = ({ commit }, freshForm) => {
  return wordpressService.freshForm(freshForm).then((page) => {
    commit('FRESH_RESULT_UPDATED', page)
  }).catch(error => {
    console.log(error)
  })
}
const freshForm2 = ({ commit }, freshForm) => {
  return wordpressService.freshForm2(freshForm).then((page) => {
    commit('FRESH_RESULT_UPDATED2', page)
  }).catch(error => {
    console.log(error)
  })
}
const goToEdit = ({ commit }, slug) => {
  return wordpressService.goToEdit(slug).then((pageId) => {
    if (pageId) {
      window.location.href = 'https://administracja.dokursynow.pl/wp-admin/post.php?post=' + pageId + '&action=edit'
    }
  }).catch(error => {
    console.log(error)
  })
}

export {
  getEvents,
  getPost,
  getPage,
  getHomepage,
  getCalendarium,
  searchFor,
  getIdeas,
  getActivities,
  contactForm,
  getVolunteering,
  freshForm,
  freshForm2,
  goToEdit
}
