import Vue from "vue";
import Router from "vue-router";
import VueGtag from "vue-gtag";

// import ga from 'vue-ga'

const ThemePagePage = () => import("./views/Page.vue")
const ThemeHomePage = () => import("./views/Home.vue")
const ThemeContactPage = () => import("./views/Contact.vue")
const ThemePost = () => import("./views/Post.vue")
const ThemeEvents = () => import("./views/Events.vue")
const ThemeActivities = () => import("./views/Activities.vue")
const ThemeGallery = () => import("./views/Gallery.vue")
const ThemeCalendarium = () => import("./views/Calendarium.vue")
const ThemeNeighborIdeas = () => import("./views/NeighborIdeas.vue")
const ThemePageVolunteering = () => import("./views/Volunteering.vue")
const ThemePageVolunteeringNews = () => import("./views/VolunteeringNews.vue")
const ThemeSearch = () => import("./views/Search.vue")
const FreshFormPage = () => import("./views/FreshForm.vue")
Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    { path: '/szukaj', component: ThemeSearch, name: "szukaj" },
    { path: '/kontakt', component: ThemeContactPage },
    { path: '/onas/:page', component: ThemePagePage },
    { path: '/wydarzenia', component: ThemeEvents },
    { path: '/wydarzenia/:page(galeria)', component: ThemeGallery },
    { path: '/wydarzenia/:page(kalendarium)', component: ThemeCalendarium },
    { path: '/wydarzenia/:page(konkursy)', component: ThemePagePage },
    { path: '/wydarzenia/:page', component: ThemePost },
    { path: '/instruktor/:page', component: ThemePost },
    { path: '/zajecia', component: ThemeActivities },
    { path: '/zajecia/:page', component: ThemePost },
    { path: '/zajecia_cykliczne/:page', component: ThemePost },
    { path: '/zajecia_warsztatowe/:page', component: ThemePost },
    { path: '/onas/:page', component: ThemePagePage },
    { path: '/mal/:page(galerie-mal)', component: ThemeGallery },
    { path: '/mal/:page(dzialania)', component: ThemeNeighborIdeas },
    { path: '/pomysly-mal/:page', component: ThemePost },
    { path: '/wystawy/:page', component: ThemePost },
    { path: '/wystawy_archiwum/:page', component: ThemePost },
    { path: '/wolontariat', component: ThemePageVolunteering },
    { path: '/wolontariat/:page(aktualnosci)', component: ThemePageVolunteeringNews },
    { path: '/wolontariat/:page', component: ThemePost },
    { path: '/konkursy/:page', component: ThemePost },
    {
      path: '/doklogin', beforeEnter() {
        window.location.href = "https://administracja.dokursynow.pl/wp-admin/"
      }
    },
    { path: '/formularz', component: FreshFormPage },
    { path: '/:page', component: ThemePagePage },
    { path: '/', name: 'Home', component: ThemeHomePage },

  ],
  scrollBehavior(to) {
    if (to.hash) {
      return window.scrollTo({ top: document.querySelector(to.hash).offsetTop, behavior: 'smooth' });
    }
    return window.scrollTo({ top: 0, behavior: 'smooth' });
  }
});

Vue.use(VueGtag, {
  config: {
    id: "UA-119965541-1"
  }
}, router);

export default router